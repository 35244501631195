<template>
  <div>
    <!-- <div class="saveDayBox ">
      <span class="saveDay ft14 mr10">保留天数</span>
      <i class="el-icon-arrow-right gray mr10"></i>
      <span class="30day gray ft14">30天</span>
    </div> -->
    <p class="headTitle">
      BMS历史数据（设备：{{ $route.query.haiLiDeviceNo }}）
    </p>
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="">
        <el-date-picker
          v-model="timeArr"
          type="datetimerange"
          clearable
          format="yyyy-MM-dd HH:mm:ss"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="bmsHistoryData" class="searchBtn">搜索</el-button>
        <el-button type="primary" @click="resetting" class="restBtn">重置</el-button>
        <el-button type="primary" @click="importFile" class="imBtn">导出</el-button>
        <el-button type="primary" @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
    <div>
      <div class="chartsBox">
        <lttEcharts
          class="class-echarts"
          :option="voltageOption"
          :refLabel="voltageLabel"
        ></lttEcharts>
        <lttEcharts
          class="class-echarts"
          :option="electricityOption"
          :refLabel="electricityLabel"
        ></lttEcharts>
      </div>
      <div class="chartsBox">
        <lttEcharts class="class-echarts" :option="rateOption" :refLabel="rateLabel"></lttEcharts>
        <lttEcharts
          class="class-echarts"
          :option="electricOption"
          :refLabel="electricLabel"
        ></lttEcharts>
      </div>

      <lttEcharts :option="cellOption" :refLabel="cellLabel"></lttEcharts>
      <lttEcharts
        :option="temperatureOption"
        :refLabel="temperatureLabel"
      ></lttEcharts>
    </div>
  </div>
</template>

<script>
import lttEcharts from "@/components/lttEcharts";
// import echarts from "echarts";
import { bmsHistoryData ,BMSExport} from "@/api/facility";
export default {
  data() {
    return {
      timeArr: [],
      form: {
        haiLiDeviceNo: "",
        startTime: "",
        endTime: ""
      },
      /** 电压 */
      voltageLabel: "voltage",
      voltageOption: {},
      /** 电流 */
      electricityLabel: "electricity",
      electricityOption: {},
      /**功率 */
      rateLabel: "rate",
      rateOption: {},
      /** 电量 */
      electricLabel: "electric",
      electricOption: {},
      /** 电芯 */
      cellLabel: "cell",
      cellOption: {},
      temperatureLabel: "temperature",
      temperatureOption: {}
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.bmsHistoryData()
      }
    };
    this.initVoltageEcharts();
    this.initElectricity();
    this.initRate();
    this.initElectric();
    this.initCell();
    this.initTemperatureOption();
    this.bmsHistoryData();
  },
  methods: {
    // 重置
    resetting() {
      this.timeArr = [];
      this.bmsHistoryData();
    },
    // 导出文件
    async importFile() {
      try{
        const params = {
          ...this.form
        };
        const res = await BMSExport(params);
        if(res.result === 1){
          this.$router.push('/leaseholdMgmt/exportTask');
        }
      }catch(e){
        console.log(e);
      }
    },
    // 获取数据
    async bmsHistoryData() {
      try {
        if (this.timeArr && this.timeArr.length !== 0) {
          this.form.startTime = this.timeArr[0];
          this.form.endTime = this.timeArr[1];
        }else{//默认现在到过去一周时间
           this.form.endTime = new Date().getTime();
           this.form.startTime = new Date().getTime() - 1000*60*60*24;//上周时间
           this.$set(this.timeArr,0,this.form.startTime);
           this.$set(this.timeArr,1,this.form.endTime);

          //  this.timeArr[0] = this.form.startTime;
          //  this.timeArr[1] = this.form.endTime;

        }
        this.form.haiLiDeviceNo = this.$route.query.haiLiDeviceNo;
        const params = { ...this.form };
        const res = await bmsHistoryData(params);
        // this.$nextTick(()=>{

            let list = res.mdata.bmsHistoryData;
            // 总电压
            let voltageOptionXArr = [];
            let voltageData = [];
            list.voltageData.forEach(el => {
              voltageOptionXArr.push(el.time);
              voltageData.push(el.data);
            });
            this.voltageOption.xAxis.data = voltageOptionXArr;
            this.voltageOption.series[0].data = voltageData;
            // 总电流
            let electricityOptionXArr = [];
            let electricityOptionData = [];
            list.currentData.forEach(el => {
              electricityOptionXArr.push(el.time);
              electricityOptionData.push(el.data);
            });
            this.electricityOption.xAxis.data = electricityOptionXArr;
            this.electricityOption.series[0].data = electricityOptionData;
            // 功率板温度
            let rateOptionXArr = [];
            let rateOptionData = [];
            list.powerBoardTemperatureData.forEach(el => {
              rateOptionXArr.push(el.time);
              rateOptionData.push(el.data);
            });
            this.rateOption.xAxis.data = rateOptionXArr;
            this.rateOption.series[0].data = rateOptionData;
            // 电量数据
            let electricOptionXArr = [];
            let electricOptionData = [];
            list.socData.forEach(el => {
              electricOptionXArr.push(el.time);
              electricOptionData.push(el.data);
            });
            this.electricOption.xAxis.data = electricOptionXArr;
            this.electricOption.series[0].data = electricOptionData;
            // 电芯电压
            let maxVoltageXArr = [];
            let maxVoltageData = [];
            list.voltageDifferenceData.maxVoltageData.forEach(el => {
              maxVoltageXArr.push(el.time);
              maxVoltageData.push(el.data);
            });
            this.cellOption.series[1].data = maxVoltageData;//最高

            //x轴共用Max的
            let minVoltageXArr = [];
            let minVoltageData = [];
            list.voltageDifferenceData.minVoltageData.forEach(el => {
              minVoltageData.push(el.data);
            });
            this.cellOption.xAxis.data = maxVoltageXArr;
            this.cellOption.series[0].data = minVoltageData;//最低


            // 电池温度1-7
            let temperatureOptionXArr = [];
            let temperatureOptionData = [];
            let tempArr = Object.values(list.temperatureData);
            tempArr.shift();
            tempArr.shift();
            tempArr.forEach((arrEl,i1) => {//
              temperatureOptionData[i1] = [];
              arrEl.forEach((el,i2) => {
                if(i1 == 0){
                  temperatureOptionXArr.push(el.time);//时间数据取第一个时间
                }
                temperatureOptionData[i1].push(el.data);//所有data数据都放一个数组中
              });
            });
            this.temperatureOption.xAxis.data = temperatureOptionXArr;
            for(let i = 0;i < 7;i ++){//给7个charts赋值
              this.temperatureOption.series[i].data = temperatureOptionData[i];
            }
          // })

      } catch (e) {
        console.log(e, "e");
      }
    },
    /** 电压 */
    initVoltageEcharts() {
      // 指定图表的配置项和数据
      this.voltageOption = {
        color:['#2587e0'],
        title: {
          text: "总电压数据"
        },
        tooltip: {
          trigger: "axis"
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              // icon:''
            },
            restore: {},
            // saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: 0.5,
            label: {
              show: true,
              position: "top",
              color: "#fff",
              fontWeight: "bold",
              backgroundColor: "#2587e0",
              padding: [3, 5],
              formatter: function(val) {
                return val.data + "v";
              }
            },
            lineStyle: {
              color: "#0047b3" //折线颜色
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#c5e8fe"
                },
                {
                  offset: 1,
                  color: "#fff"
                }
              ])
            }
          }
        ]
      };
    },
    /** 电流 */
    initElectricity() {
      // 指定图表的配置项和数据
      this.electricityOption = {
        color:['#23e199'],
        title: {
          text: "电流数据"
        },
        tooltip: {
          trigger: "axis"
        },
         toolbox: {
          show: true,
          feature: {
            dataZoom: {
              // icon:''
            },
            restore: {},
            // saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [1, "2", "3", "4", "5", "6", "90"]
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: 0.5,
            label: {
              show: true,
              position: "top",
              color: "#fff",
              fontWeight: "bold",
              backgroundColor: "#23e199",
              padding: [3, 5],
              formatter: function(val) {
                return val.data + "A";
              }
            },
            lineStyle: {
              color: "#23e199" //折线颜色
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#23e199"
                },
                {
                  offset: 1,
                  color: "#fff"
                }
              ])
            }
          }
        ]
      };
    },
    /** 功率 */
    initRate() {
      // 指定图表的配置项和数据
      this.rateOption = {
        color:["#fab02b"],
        title: {
          text: "功率板温度"
        },
        tooltip: {
          trigger: "axis"
        },
         toolbox: {
          show: true,
          feature: {
            dataZoom: {
              // icon:''
            },
            restore: {},
            // saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [1, "2", "3", "4", "5", "6", "90"]
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: 0.5,
            label: {
              show: true,
              position: "top",
              color: "#fff",
              fontWeight: "bold",
              backgroundColor: "#fab02b",
              padding: [3, 5],
              formatter: function(val) {
                return val.data + "°C";
              }
            },
            lineStyle: {
              color: "#fab02b" //折线颜色
            },
            // itemStyle: {
            //   color: "#fff"
            // },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#fab02b"
                },
                {
                  offset: 1,
                  color: "#fff"
                }
              ])
            }
          }
        ]
      };
    },
    /** 电量 */
    initElectric() {
      // 指定图表的配置项和数据
      this.electricOption = {
        color:['#ff6f82'],
        title: {
          text: "电量数据"
        },
        tooltip: {
          trigger: "axis"
        },
         toolbox: {
          show: true,
          feature: {
            dataZoom: {
              // icon:''
            },
            restore: {},
            // saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [1, "2", "3", "4", "5", "6", "90"]
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: 0.5,
            label: {
              show: true,
              position: "top",
              color: "#fff",
              fontWeight: "bold",
              backgroundColor: "#ff6f82",
              padding: [3, 5],
              formatter: function(val) {
                return val.data + "%";
              }
            },
            lineStyle: {
              color: "#ff6f82" //折线颜色
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#ff6f82"
                },
                {
                  offset: 1,
                  color: "#fff"
                }
              ])
            }
          }
        ]
      };
    },
    /**电芯 */
    initCell() {
      // 指定图表的配置项和数据
      this.cellOption = {
        color: [
          "#23e199",
          "#2587e0",
        ],
        title: {
          text: "电芯电压"
        },
         toolbox: {
          show: true,
          feature: {
            dataZoom: {
              // icon:''
            },
            restore: {},
            // saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [1, "2", "3", "4", "5", "6", "90"]
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
         tooltip: {
          trigger: "axis"
        },
         legend: {
          show: true,
          data: ["最低电压", "最高电压"]
        },
        series: [
          {//最低
            name: "最低电压",
            data: [],
            type: "line",
            smooth: 0.5,
            label: {
              show: true,
              position: "top",
              color: "#fff",
              fontWeight: "bold",
              backgroundColor: "#23e199",
              padding: [3, 5],
              formatter: function(val) {
                return val.data + "V";
              }
            },
            lineStyle: {
              color: "#23e199" //折线颜色
            },

            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#23e199"
                },
                {
                  offset: 1,
                  color: "#fff"
                }
              ])
            }
          },
          {//最高
            data: [],
            type: "line",
            name: "最高电压",
            smooth: 0.5,
            label: {
              show: true,
              position: "top",
              color: "#fff",
              fontWeight: "bold",
              backgroundColor: "#2587e0",
              padding: [3, 5],
              formatter: function(val) {
                return val.data + "V";
              }
            },
            lineStyle: {
              color: "#2587e0" //折线颜色
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#2587e0"
                },
                {
                  offset: 1,
                  color: "#fff"
                }
              ])
            }
          }
        ]
      };
    },
    // 电温度
    initTemperatureOption() {
      const self = this;
      this.temperatureOption = {
        color: [
          "orange",
          "#23e199",
          "#2587e0",
          "#fab02b",
          "#ff6f82",
          "gold",
          "pink"
        ],
         toolbox: {
          show: true,
          feature: {
            dataZoom: {
              // icon:''
            },
            restore: {},
            // saveAsImage: {}
          }
        },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          show: true,
          data: ["电池1", "电池2", "电池3", "电池4", "电池5", "电池6", "电池7"]
        },
        title: {
          text: "电池温度"
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "18:00:00",
            "19:00:00",
            "20:00:00",
            "21:00:00",
            "22:00:00",
            "10:00:00",
            "12:00:00"
          ]
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        series: [
          {
            data: [],
            type: "line",
            name: "电池1",
            smooth: 0.5,
            lineStyle: {
              color: "orange" //折线颜色
            },
            label: {
              show: true,
              position: "top",
              color: "#fff",
              fontWeight: "bold",
              backgroundColor: "orange",
              padding: [3, 5],
              formatter: function(val) {
                return val.data + "°C";
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "orange"
                },
                {
                  offset: 1,
                  color: "#fff"
                }
              ])
            }
          },
          {
            data: [],
            type: "line",
            name: "电池2",
            smooth: 0.5,
            lineStyle: {
              color: "#23e199" //折线颜色
            },
            label: {
              show: true,
              position: "top",
              color: "#fff",
              fontWeight: "bold",
              backgroundColor: "#23e199",
              padding: [3, 5],
              formatter: function(val) {
                return val.data + "°C";
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#23e199"
                },
                {
                  offset: 1,
                  color: "#fff"
                }
              ])
            }
          },
          {
            data: [],
            type: "line",
            smooth: 0.5,
            name: "电池3",
            lineStyle: {
              color: "#2587e0" //折线颜色
            },
            label: {
              show: true,
              position: "top",
              padding: [3, 5],
              color: "#fff",
              fontWeight: "bold",
              backgroundColor: "#2587e0",
              formatter: function(val) {
                return val.data + "°C";
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#2587e0"
                },
                {
                  offset: 1,
                  color: "#fff"
                }
              ])
            }
          },
          {
            data: [],
            type: "line",
            name: "电池4",
            smooth: 0.5,
            lineStyle: {
              color: "#fab02b" //折线颜色
            },
            label: {
              show: true,
              position: "top",
              color: "#fff",
              fontWeight: "bold",
              backgroundColor: "#fab02b",
              padding: [3, 5],
              formatter: function(val) {
                return val.data + "°C";
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#fab02b"
                },
                {
                  offset: 1,
                  color: "#fff"
                }
              ])
            }
          },
          {
            data: [],
            type: "line",
            smooth: 0.5,
            name: "电池5",
            lineStyle: {
              color: "#ff6f82" //折线颜色
            },
            label: {
              show: true,
              position: "top",
              padding: [3, 5],
              color: "#fff",
              fontWeight: "bold",
              backgroundColor: "#ff6f82",
              formatter: function(val) {
                return val.data + "°C";
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#ff6f82"
                },
                {
                  offset: 1,
                  color: "#fff"
                }
              ])
            }
          },
          {
            data: [],
            type: "line",
            smooth: 0.5,
            name: "电池6",
            lineStyle: {
              color: "gold" //折线颜色
            },
            label: {
              show: true,
              position: "top",
              padding: [3, 5],
              color: "#fff",
              fontWeight: "bold",
              backgroundColor: "gold",
              formatter: function(val) {
                return val.data + "°C";
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "gold"
                },
                {
                  offset: 1,
                  color: "#fff"
                }
              ])
            }
          },
          {
            data: [],
            type: "line",
            smooth: 0.5,
            name: "电池7",
            lineStyle: {
              color: "pink" //折线颜色
            },
            label: {
              show: true,
              position: "top",
              color: "#fff",
              fontWeight: "bold",
              backgroundColor: "pink",
              padding: [3, 5],
              formatter: function(val) {
                return val.data + "°C";
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "pink"
                },
                {
                  offset: 1,
                  color: "#fff"
                }
              ])
            }
          }
        ]
      };
    }
  },
  components: {
    lttEcharts
  }
};
</script>

<style scoped lang="less">
.searchBtn {
  background: rgb(17, 173, 194);
  border: 1px solid rgb(17, 173, 194);
}
/* 导出按钮 */
.imBtn {
  background: rgb(28, 240, 116);
  border: 1px solid rgb(28, 240, 116);
}
/* 重置按钮 */
.restBtn {
  background: rgb(240, 173, 28);
  border: 1px solid rgb(240, 173, 28);
}
.chartsBox {
  display: flex;
  .class-echarts {
    flex: 1;
  }
}
.headTitle {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
}
.saveDay {
  color: blue;
}
.gray {
  color: gray;
}
.saveDayBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
